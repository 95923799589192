import React from 'react';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { A } from '../components/a';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

const Imprint = () => {
  return (
    <div id="main_content">
      <Seo title='Imprint'/>
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Imprint</h1>

            <ul className="bradcurmed">
              <li>
                <A rel="noopener noreferrer">Home</A>
              </li>
              <li>Imprint</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="portfolio-single">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                <strong>Company name:</strong> NETING Informatika Korlátolt Felelősségű Társaság
              </p>
              <p>
                <strong>Abbreviated name of the company:</strong> NETING Kft.
              </p>
              <p>
                <strong>Registered seat:</strong> 1162 Budapest, Hársfa utca 122.
              </p>
              <p>
                <strong>Email:</strong> info@neting.hu
              </p>
              <p>
                <strong>Tax number:</strong> 12941258-2-42
              </p>
              <p>
                <strong>Hosting service:</strong> Invitel Távközlési Zrt. (Registered seat: 1134
                Budapest, Váci út 37., email: info@invitel.co.hu)
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Imprint;
